<template>
    <div class="container-activation">
        <section class="wrapper-activacion">

            <div class="wrapper-logo">
                <img class="logo-app-login"
                    src="../assets/images/logo-dembora-horiz.svg"
                    alt="Logo product">
            </div>

            <div v-if="isPendingToActivate">
                <p>{{ $t('activation.welcome') }}</p>
                <form @submit.prevent="onSubmit">

                    <div class="mdl-textfield mdl-js-textfield">
                        <label class="activation-label" for="password">
                            {{ $t('activation.password-placeholder') }}
                        </label>
                        <input class="mdl-textfield__input activation-input" type="password" v-model="password"
                               id="password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                               :title="passwordTitle"
                               required>
                    </div>

                    <div class="mdl-textfield mdl-js-textfield">
                        <label class="activation-label" for="verify-password">
                            {{ $t('activation.password-verify-placeholder') }}
                        </label>
                        <input class="mdl-textfield__input activation-input" type="password"
                               v-model="passwordVerification" id="verify-password"
                               pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                               :title="passwordTitle"
                               required>
                    </div>

                    <div class="wrapper-checkbox-legal">
                        <label class="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect checkbox-legal"
                               for="checkbox-legal">
                            <input type="checkbox" id="checkbox-legal" v-model="checkedLegal" class="mdl-checkbox__input" required>

                            <span class="label-legal">
                                {{ $t('activation.legal-account')}}
                            </span>

                        </label>

                        <a class="label-legal-link" :href="url" target="_blank">{{ $t('activation.legal-account-link-text')}}</a>

                    </div>

                    <div class="wrapper-submit">
                        <button type="submit" value="Activate"
                                class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect">
                            {{ $t('activation.activate') }}
                        </button>
                    </div>

                </form>
            </div>

            <div v-else>
                <p>{{ $t('activation.account-activated', {email: this.email}) }}</p>
                <router-link class="mdl-navigation__link setting" :to="{ name: 'Login' }" exact>{{ $t('login.login')}}
                </router-link>
            </div>
            <vue-element-loading :active="show" spinner="bar-fade-scale" color="#FF6700"/>
        </section>
    </div>
</template>

<script>
    import {mapActions, mapState} from 'vuex'
    import VueElementLoading from 'vue-element-loading'

    export default {
        name: "AccountActivation",
        data() {
            return {
                isPendingToActivate: true,
                token: '',
                email: '',
                password: '',
                passwordVerification: '',
                checkedLegal: false,
                passwordTitle: this.$i18n.t('shared.password-not-valid'),
                url: this.$i18n.t('activation.legal-account-link')
            }
        },
        created() {
            this.token = this.$route.params.token
            this.email = this.$route.params.email
        },
        computed: {
            ...mapState({
                show: state => state.auth.requesting
            })
        },
        methods: {
            ...mapActions('auth', ['activateAccount']),
            async onSubmit() {
                if (this.password !== this.passwordVerification) {
                    this.$toast.error(this.$i18n.t('shared.password-mismatch'))
                    return
                }

                if (this.checkedLegal === false) {
                    this.$toast.error(this.$i18n.t('activation.error-no-legal'))
                    return
                }

                try {
                    await this.activateAccount({
                        email: this.email,
                        token: this.token,
                        password: this.password
                    })
                    this.isPendingToActivate = false
                } catch (error) {
                    this.$toast.error(this.$i18n.t(error.message))
                }
            }
        },
        components: { VueElementLoading }
    }
</script>
